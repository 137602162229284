var projetSlides = document.querySelectorAll('.projet-slide'),
	navSmall = document.getElementById('nav-small'),
    nav = document.getElementById('nav-main'),
    categories = document.getElementById('categories-list'),
	homeCounter =  document.getElementById('home-counter'),
	projetCounter =  document.getElementById('projet-counter'),
	menuLinks = document.querySelectorAll('nav a'),
	projetCaroussel = document.getElementById('projet-caroussel'),
    projetPanel = document.getElementById('projet-panel'),
    cat = document.getElementById('cat-content'),
    catBtn = document.getElementById('cat-btn'),
	currentSlideProjet = 0,
    currentSlideLoop = 0,
    logo = document.getElementById('logo'),
    logos = ['/assets/images/logotype-web-1.svg', '/assets/images/logotype-web-2.svg', '/assets/images/logotype-web-3.svg', '/assets/images/logotype-web-4.svg'];
let translate = 0,
    translateSlide = 0,
    moveSlide = 0,
    translateSlideUp = 0,
    end = 0,
    expand = 0;
const currentPage = window.location.href;

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

//Height Mobile

if (typeof(caroussel) != 'undefined' && caroussel != null){
    if(window.matchMedia("(max-width: 800px)").matches){
        document.getElementById('home-content').style.minHeight = (window.innerHeight - 10) + "px";
    }
}
if (typeof(projetCaroussel) != 'undefined' && projetCaroussel != null){
    if(window.matchMedia("(max-width: 800px)").matches){
        document.getElementById('projet-content').style.minHeight = (window.innerHeight - 10) + "px";
    }
}

// Logo generator

function random(){
    if(sessionStorage.getItem('storedCurrent') == null){
        sessionStorage.setItem('storedCurrent', 0);
    }
    var newIndex = Math.floor(Math.random()*logos.length),
        oldIndex = sessionStorage.getItem('storedCurrent');
    while(newIndex == oldIndex){
        newIndex = Math.floor(Math.random()*logos.length);
    }
    sessionStorage.setItem('storedCurrent', newIndex);
    logo.src = logos[newIndex];
}

document.addEventListener("DOMContentLoaded", function(){
    random();
});

// liens

document.addEventListener("readystatechange", function() {
    for (var i = 0; i < menuLinks.length; i++) {
        if(menuLinks[i].href === currentPage){
            menuLinks[i].classList.add('active');
        }
    }
})

// Small Nav Anim

window.onscroll = function() {navSmallAnim()};

function navSmallAnim(){
	var navMainHeight = document.getElementById('nav-main').scrollHeight;
	if (document.body.scrollTop > navMainHeight && document.body.scrollTop < (navMainHeight + 20) || document.documentElement.scrollTop > navMainHeight && document.documentElement.scrollTop < (navMainHeight + 20)) {
        navSmall.classList.add('slide-top');
        if (typeof(categories) != 'undefined' && categories != null){
        categories.classList.add('pre-slide-top');
        document.body.classList.add('top-offset');
        }
	}else if(document.body.scrollTop > (navMainHeight + 10) || document.documentElement.scrollTop > (navMainHeight + 10)) {
	   navSmall.classList.add('slide-top');
        if (typeof(categories) != 'undefined' && categories != null){
        categories.classList.add('slide-top');
        categories.classList.remove('pre-slide-top');
        document.body.classList.add('top-offset');
        }
	}else{
        navSmall.classList.remove('slide-top');
        if (typeof(categories) != 'undefined' && categories != null){
        categories.classList.remove('slide-top');
        categories.classList.remove('pre-slide-top');
        document.body.classList.remove('top-offset');
        }
    }
}
if (typeof(projetCaroussel) != 'undefined' && projetCaroussel != null){
    window.onload = function() {
        navSmall.className = 'expand';
    };
}

// Categories 

var pressEls = document.querySelectorAll('#cat-content a');
var classes = [].map.call(pressEls, function(el) {
  return el.className;
});
var elms = {};
var n = {}, nclasses = classes.length;
function changeColor(classname, color) {
    var curN = n[classname];
    for(var i = 0; i < curN; i ++) {
        elms[classname][i].querySelector('span').style.fontFamily = color;
    }
}
for(var k = 0; k < nclasses; k ++) {
    var curClass = classes[k];
    elms[curClass] = document.getElementsByClassName(curClass);
    n[curClass] = elms[curClass].length;
    var curN = n[curClass];
    for(var i = 0; i < curN; i ++) {
        elms[curClass][i].onmouseover = function() {
            if(expand == 1){
                changeColor(this.className, "dpinverse");
            }
        };
        elms[curClass][i].onmouseout = function() {
            changeColor(this.className, "");
        };
    }
};

// Caroussel Projet

if (typeof(projetCaroussel) != 'undefined' && projetCaroussel != null){

var prev = document.getElementById('prev'),
	next = document.getElementById('next'),
    info = document.getElementById('info'),
    infoBtn = document.querySelector('#info span'),
    container = document.querySelector(".container"),
    innerContainer =  document.getElementById('inner-container'),
    slidesOnScreen = 2,
    slidesLength = projetSlides.length,
    firstSlide = projetSlides[currentSlideProjet],
    projetSlidesAr = Array.prototype.slice.call(projetSlides, 0);
    var projetSlideWidth = projetSlidesAr[0].offsetWidth + 10;

if(window.matchMedia("(max-width: 800px)").matches){
        var slidesOnScreen = 1;
        var auto = setInterval(nextSlideProjet, 2000);
}

// Cursor edge debug

next.addEventListener("mouseenter", function() {

    if(!CSS.supports('cursor', 'url(cursor.png), pointer')) {
        var myCursor = document.createElement('img');

        myCursor.src = '/assets/images/arrow-right.png';
        myCursor.style.position = 'absolute';
        document.body.appendChild(myCursor);

        document.addEventListener('mousemove', function(e) {
            myCursor.style.left = e.pageX+'px';
            myCursor.style.top = e.pageY+'px';
        }, false);
    }
});

prev.addEventListener("mouseenter", function() {

    if(!CSS.supports('cursor', 'url(cursor.png), pointer')) {
        var myCursor = document.createElement('img');

        myCursor.src = '/assets/images/arrow-left.png';
        myCursor.style.position = 'absolute';
        document.body.appendChild(myCursor);

        document.addEventListener('mousemove', function(e) {
            myCursor.style.left = e.pageX+'px';
            myCursor.style.top = e.pageY+'px';
        }, false);
    }
});

// Resize control

function debounce(func){
  var timer;
  return function(event){
    if(timer) clearTimeout(timer);
    timer = setTimeout(func,100,event);
  };
}

window.addEventListener("resize", debounce(function(e) {
    if(currentSlideLoop >= 1){
        location.reload();
    }
}));

// Click events

next.addEventListener('click', function(){
    nextSlideProjet();
    clearInterval(auto);
});

prev.addEventListener('click', function(){
    prevSlideProjet();
    clearInterval(auto);
});

// Calcul position image

function PerformCalc() {
  if (currentSlideLoop <= (slidesLength - 1)) {
    currentSlideLoop += 1
  } else {
      currentSlideLoop = 0;
      currentSlideLoop += 1;
  }
};

function PerformCalcDown() {
  if (currentSlideLoop >= (-slidesLength)) {
    currentSlideLoop -= 1
  } else {
      currentSlideLoop = 0;
      currentSlideLoop += 1;
  }
};

function nextSlideProjet() {

    projetSlideWidth = projetSlidesAr[0].offsetWidth + 10;
    translate -= projetSlideWidth;
    container.style.transform = "translateX(" + translate + "px" + ")";

    PerformCalc();

    projetSlidesAr[0].style.transitionDelay = "0.5s";

    if(slidesOnScreen == 1){
        moveSlide = (projetSlideWidth * slidesLength);
    }else{
        moveSlide = innerContainer.offsetWidth;
    }
    translateSlide = moveSlide + translateSlideUp;
    projetSlidesAr[0].style.transform = "translateX(" + translateSlide  + "px" + ")";
    projetSlidesAr.push(projetSlidesAr.splice(projetSlidesAr.indexOf[projetSlidesAr[0]], 1)[0]);
    if (currentSlideLoop == slidesLength) {
        translateSlideUp += moveSlide;
    }
    console.log(projetSlideWidth);
}

    // if((currentSlideProjet + (slidesOnScreen-1)) < (projetSlides.length - 1) && end == 0){
    // }
    // }else if(currentSlideProjet == 0){
    //     currentSlideProjet = (currentSlideProjet+1)%projetSlides.length;
    //     var projetSlideWidth = projetSlides[currentSlideProjet -1].offsetWidth + 10;
    //     translate -= projetSlideWidth;
    //     container.style.transform = "translateX(" + translate + "px" + ")";
    //     end = 0;
    // }else if((currentSlideProjet + slidesOnScreen) == projetSlides.length && end == 0){
    //     currentSlideProjet = (currentSlideProjet+1)%projetSlides.length;
    //     var projetSlideWidth = projetSlides[currentSlideProjet -1].offsetWidth + 10;
    //     translate -= projetSlideWidth;
    //     container.style.transform = "translateX(" + translate + "px" + ")";
    //     end = 1;
    // }else if(end == 1 && (currentSlideProjet + (slidesOnScreen-1)) <= (projetSlides.length - 1)){
    //     currentSlideProjet = (currentSlideProjet+1)%projetSlides.length;
    //     var projetSlideWidth = projetSlides[currentSlideProjet -1].offsetWidth + 10;
    //     translate -= projetSlideWidth;
    //     container.style.transform = "translateX(" + translate + "px" + ")";
    //     end = 1;
    // }


function prevSlideProjet() {

    if(currentSlideLoop >= 1){
        projetSlideWidth = projetSlidesAr[projetSlidesAr.length - 1].offsetWidth + 10;
        translate += projetSlideWidth;
        container.style.transform = "translateX(" + translate + "px" + ")";

        if (currentSlideLoop == slidesLength) {
            translateSlideUp -= moveSlide;
        }

        projetSlidesAr[projetSlidesAr.length - 1].style.transitionDelay = "0s";

        PerformCalcDown();

        translateSlide = 0 + translateSlideUp;
        projetSlidesAr[projetSlidesAr.length - 1].style.transform = "translateX(" + translateSlide + "px" + ")";
        projetSlidesAr.unshift(projetSlidesAr.pop())
    }

    console.log(translateSlide);
}

    // }else if(currentSlideProjet == 1 && end == 1){
    //     currentSlideProjet = (currentSlideProjet-1)%projetSlides.length;
    //     var projetSlideWidth = projetSlides[currentSlideProjet].offsetWidth + 10;
    //     translate += projetSlideWidth;
    //     container.style.transform = "translateX(" + translate + "px" + ")";
    // }else if(currentSlideProjet > 1 && end == 1){
    //     currentSlideProjet = (currentSlideProjet-1)%projetSlides.length;
    //     var projetSlideWidth = projetSlides[currentSlideProjet].offsetWidth + 10;
    //     translate += projetSlideWidth;
    //     container.style.transform = "translateX(" + translate + "px" + ")";
    // }

info.addEventListener('click', function(){
    if (projetPanel.style.maxHeight){
        projetPanel.style.maxHeight = null;
        projetPanel.classList.remove('slow-up');
    } else {
        projetPanel.style.maxHeight = projetPanel.scrollHeight + "px";
        projetPanel.classList.add('slow-up');
        setTimeout(function(){projetPanel.scrollIntoView({ behavior: 'smooth', block: 'end' });},60)
    }
    if(infoBtn.innerHTML == '?'){
        infoBtn.innerHTML = '×';
    }else{
        infoBtn.innerHTML = '?';
    }
});

}

// Categories show

document.getElementById('cat-title').addEventListener('click', function(){
    cat.classList.toggle('slide-right');
    if(catBtn.innerHTML == '+'){
        catBtn.innerHTML = '−';
        expand = 1;
    }else{
        catBtn.innerHTML = '+';
        expand = 0;
    }
});

// Lazy load

document.addEventListener("DOMContentLoaded", function() {
  var lazyloadImages;    

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".lazy");
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.classList.remove("lazy");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function(image) {
      imageObserver.observe(image);
    });
  } else {  
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll(".lazy");
    
    function lazyload () {
      if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }    

      lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
              img.src = img.dataset.src;
              img.classList.remove('lazy');
            }
        });
        if(lazyloadImages.length == 0) { 
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
})



